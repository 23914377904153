import { Component, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { MatSidenav, MatDialog } from '@angular/material';
import { AppContextService } from '../../../../code-modules/app-context/app-context.service';
import { AuthService } from '../../../../code-modules/authentication/auth.service';
import { ModuleIds, ModuleUrl, UserRoles } from '../../../../code-modules/saas/saas.enums';
import { ActivatedRoute, Router } from '@angular/router';
import { InAppLogger } from '../../../../code-modules/utils/general-utils/InAppLogger';
import { WebSocketService, NotificationTypes } from '../../../../code-modules/websocket/web-socket.service';
import { SidenavService } from './sidenav.service';
import { changePasswordUrl, loginUrl, noPermissionUrl } from 'src/app/code-modules/authentication/auth.urls';
import { interval, Subscription } from 'rxjs';
import { UserProfileRead } from '../../../../code-modules/api/interfaces/User/user-api-interfaces';
import { NotificationRead } from '../../../../code-modules/api/interfaces/Business-Rule/business-rule-api-interfaces';
import { DialpadComponent } from '../dialpad/dialpad.component';
import { ApiService } from 'src/app/code-modules/api/api-services/api.service';
import * as $ from 'jquery';
import { Phone } from 'src/app/circle-one/telephony/model/phone.model';
import { PhoneService } from 'src/app/circle-one/telephony/services/phone.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { NotificationPopupComponent } from '../notification-popup/notification-popup.component';
import { IncomingPopupComponent } from 'src/app/circle-one/navbar/components/incoming-popup/incoming-popup.component';
import { PhoneLoginReport } from 'src/app/code-modules/api/interfaces/Reports/report-api-interfaces';
import { DispositionCreate, IBCallEntryCreate, OBCallEntryCreate } from 'src/app/code-modules/api/interfaces/Ticket/ticket-api-interfaces';
import { LoaderService } from 'src/app/code-modules/utils/loader/loader.service';
import { SwitchTeamDialogComponent } from './switch-team-dialog/switch-team-dialog.component';
import { TelephonyDispositionDialogComponent } from '../telephony-disposition-dialog/telephony-disposition-dialog.component';
// import { animate } from 'highcharts';
import { AddCustomerContactDialogComponent } from 'src/app/circle-one/app/components/add-customer-contact-dialog/add-customer-contact-dialog.component';
import { AddCustomerAccountDialogComponent } from 'src/app/circle-one/app/components/add-customer-account-dialog/add-customer-account-dialog.component';
import { TicketService } from 'src/app/circle-one/tickets/services/tickets.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.sass'],
  // animations: [
  //   trigger('indicatorRotate', [
  //     state('collapsed', style({transform: 'rotate(0deg)'})),
  //     state('expanded', style({transform: 'rotate(180deg)'})),
  //     transition('expanded <=> collapsed',
  //       animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
  //     ),
  //   ])
  // ]
})
export class SideNavComponent implements OnInit, OnDestroy {
  logger = new InAppLogger(SideNavComponent.name);
  phoneLogout: boolean = true;
  navPhoneLogout: boolean;
  phoneLogin: boolean;
  navPhoneLogin: boolean = true;
  disposCall: boolean;
  pauseCode: string;
  dialObCall: boolean = true;
  hangupObCall: boolean = false;
  parkBtn: boolean;
  unparkBtn: boolean;
  parkCustomer: string;
  telLoginEvent: any;
  callTimer: boolean = false;
  existTime: any = [];
  callTimerEvent: any;
  url: Window;
  disposData: DispositionCreate
  panelOpenState = false;
  NotificationIcons = {
    [NotificationTypes.NOTIFICATION_EMAIL_NEW_ASSIGN]: "email",
    [NotificationTypes.NOTIFICATION_KM_NEW_ENTRY]: "emoji_objects_outline",
    [NotificationTypes.NOTIFICATION_EMAIL_NEW_INCOMING]: "email",
    [NotificationTypes.NOTIFICATION_TICKET_NEW_ASSIGN]: "assignment_turned_in",
    [NotificationTypes.NOTIFICATION_TICKET_RESOLVED]: "assignment_turned_in",
    [NotificationTypes.NOTIFICATION_LEAD_NEW_ASSIGN]: "contacts"
  }
  NotificationDescription = {
    [NotificationTypes.NOTIFICATION_EMAIL_NEW_ASSIGN]: "New email has been assigned to you",
    [NotificationTypes.NOTIFICATION_KM_NEW_ENTRY]: "New KM entry created",
    [NotificationTypes.NOTIFICATION_EMAIL_NEW_INCOMING]: "New email received",
    [NotificationTypes.NOTIFICATION_TICKET_NEW_ASSIGN]: "New ticket has been assigned to you",
    [NotificationTypes.NOTIFICATION_TICKET_RESOLVED]: "A ticket has been resolved",
    [NotificationTypes.NOTIFICATION_LEAD_NEW_ASSIGN]: "New lead has been assigned to you"
  }
  expanded: boolean

  notificationTypes = NotificationTypes
  companyLogo = "/assets/new-circleone.png"
  footerLogo = "/assets/botgo.png"
  sideExpanded = true;
  sideNavHideOn = 490;
  moduleIds = ModuleIds;
  avatar = 'url(https://www.w3schools.com/howto/img_avatar.png)';
  showFilterBy = false;
  notifications: any = [];
  pingToSocket: Subscription
  currentUser: UserProfileRead
  filterPermission = false
  selectedPause: string = 'Not Ready';
  @ViewChild('sideNav', { static: false }) sidenav: MatSidenav;
  transferCall: boolean;
  endConfCall: boolean;
  endSingleCall: boolean;
  confEnd: string;
  emailInterval: any;
  ticketInterval: any;
  cyretReport: boolean = false;
  allReport: boolean = false;
  webphoneUrl: Window;
  resumeBtn: boolean;
  pauseBtn: boolean;
  allPauseCode: boolean;
  dialBox: boolean = true;
  conCallBox: boolean = false;
  isDisable = false
  inboundCall: any;
  newContactData: any = [];
  phoneData: PhoneLoginReport;
  leaveCall: boolean;
  disposCode: any;
  rtmEvents = []
  loginTeam
  phoneStatusInterval: Subscription;
  pauseCodeArr: any = [];
  disposCallData: { action: string; dispose_remark: any; };
  disposCodeArr = [
    { key: 'Answered', value: 'Answered' },
    { key: 'Busy', value: 'Busy' },
    { key: 'Disconnected', value: 'Disconnected' },
    { key: 'Not Intrested', value: 'Not Intrested' },
    { key: 'No Answer', value: 'No Answer' },
    { key: 'Call Transfer', value: 'Call Transfer' },
  ];
  phoneOnCall: boolean;
  phoneOnPause: boolean;
  phoneOnAcw: boolean;
  tenant: string;
  pincPauseCode: boolean = false;
  biosachPauseCode: boolean = false;
  commonPauseCode: boolean = false;
  blindTransfer: boolean = false;
  obcallData: OBCallEntryCreate;
  ibcallData: IBCallEntryCreate;
  allPriList: any;
  priSelected: any = "0";
  priBox: boolean = true;
  priNumber: any;
  phoneNumber: any;
  extStateData: { extension: number; state: string; };
  breakData: { user_id: number; break_remark: string; };
  teleSessionData: any;
  sessionPhoneData: any = [];
  resumeData: { action: string; };
  holdData: { action: string; };
  pauseCodeText: boolean;
  language: string;
  pauseCodeData: { name: string; };

  unMaskedDialPadValue: number;
  dialInputValue: string

  // when screen is resized
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth < this.sideNavHideOn) {
      this.sideNavController.close();
    } else {
      // this.sideNavController.open();
    }
  }



  constructor(
    public context: AppContextService, // used in template
    public auth: AuthService, // used in template
    public api: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    private socketConnection: WebSocketService,
    public sideNavController: SidenavService,
    public dialPadDialog: MatDialog,
    public telephonyLoginDialog: MatDialog,
    public notificationDialog: MatDialog,
    public loader: LoaderService,
    public phoneModel: Phone,
    private phoneApi: PhoneService,
    public toaster: ToastrService,
    public popupPadDialog: MatDialog,
    public switchTeamDialog: MatDialog,
    public telephonyDispoDialog: MatDialog,
    public addCustomerContactDialog: MatDialog,
    public addCustomerAccountDialog: MatDialog,
    private ticketservice: TicketService
  ) {
    this.getPhoneServerUrl()
  }

  // open() {
  //   this.sideNavController.open();
  // }

  close() {
    this.sideNavController.close();
  }

  // when the screen is initialized onLoad
  ngOnInit() {
    // this.ticketservice.selectedWorkFlow.next({})
    // localStorage.removeItem('options')
    this.sideNavController.dialInputValue.subscribe((val) => this.dialInputValue = val)
    $('#conCallBox').hide();
    this.getAllPauseCode()
    /*stop autorefresh interval of email & ticket */
    this.emailInterval = localStorage.getItem('emailInterval');
    if (this.emailInterval) {
      clearInterval(this.emailInterval);
    }

    this.ticketInterval = localStorage.getItem('ticketInterval');
    if (this.ticketInterval) {
      clearInterval(this.ticketInterval);
    }
    /*end of function*/
    this.sideNavController.close();
    //fix for duplicate notification from front end
    // const all_notification: NotificationRead[] = this.context.notifications;
    // console.log(all_notification)
    // let distinct_notification: number[] = []
    // this.notifications = all_notification.filter((item) => {
    //   if (distinct_notification.includes(item.id)) {
    //     return false
    //   } else {
    //     distinct_notification.push(item.id)
    //     return true
    //   }
    // })

    this.context.currentUser$.subscribe(user => {
      this.currentUser = user
      console.log(this.currentUser, "current usr")
      // this.tenant = ((this.currentUser) ? this.currentUser.tenant.sub_domain : null);

      console.log(this.currentUser, "Manage")
      if (this.currentUser.tenant.sub_domain == 'amazeu' || this.currentUser.tenant.sub_domain == 'seaview' || this.currentUser.tenant.sub_domain == 'sixt') {
        this.isDisable = true
        // alert(this.isDisable)
      }
      this.filterPermission = user.role !== UserRoles.AGENT ? true : false
      if (user.avatar) {
        console.log('new avatar');
        this.avatar = 'url(' + user.avatar + '?random=' + String(Number(new Date())) + ')';
      }
      this.loginTeam = JSON.parse(localStorage.getItem("currentTeam"))[0]
      console.log('User Permissions Array:', JSON.parse(localStorage.getItem("currentTeam"))[0].manager_module_permissions);
      if (JSON.parse(localStorage.getItem("currentTeam"))[0].manager_module_permissions) {
        if (user.require_password_change) {
          console.log("redirectng to change pwd url", changePasswordUrl)
          this.router.navigate([changePasswordUrl]);
        }
        else if (JSON.parse(localStorage.getItem("currentTeam"))[0].manager_module_permissions.length === 0) {
          console.log("redirecting", user)
          this.router.navigate(['/app/no-permission']);
        }
        this.notifications = this.context.notifications;
      } else if (JSON.parse(localStorage.getItem("currentTeam"))[0].agent_module_permissions) {
        if (user.require_password_change) {
          console.log("redirectng to change pwd url", changePasswordUrl)
          this.router.navigate([changePasswordUrl]);
        }
        else if (JSON.parse(localStorage.getItem("currentTeam"))[0].agent_module_permissions.length === 0) {
          console.log("redirecting", user)
          this.router.navigate(['/app/no-permission']);
        }
        this.notifications = this.context.notifications;
      } else {
        if (user.require_password_change) {
          console.log("redirectng to change pwd url", changePasswordUrl)
          this.router.navigate([changePasswordUrl]);
        }
        else if (JSON.parse(localStorage.getItem("currentTeam"))[0].module_permissions.length === 0) {
          console.log("redirecting", user)
          this.router.navigate(['/app/no-permission']);
        }
        this.notifications = this.context.notifications;
      }
      this.notifications = this.context.notifications;
      console.log("Notification1", this.notifications);



      console.log("Notification", this.notifications);

      // console.log("Notification1", this.notifications);
      // console.log("Notification", this.notifications);


      // this.companyLogo = user.tenant && user.tenant.logo ? user.tenant.logo + '?random=' + String(Number(new Date())) : this.companyLogo;

    });


    if ((window.innerWidth) < this.sideNavHideOn) {
      this.sideNavController.close();
      // this.sideExpanded = false;
    }
    this.telephonyInit()
    this.phoneModel.loginToken && this.phoneModel.loginToken.unsubscribe();
    this.checkToken()
    let dialPadCall = this.phoneModel.dialPadCall
    let leadId = this.phoneModel.leadId;
    console.log(leadId, 'leadId')

    let mobileNo = this.phoneModel.mobileNo

    if (dialPadCall == 'active') {
      this.phoneModel.dialPadCall = ''
      this.dial()
    }

    if (dialPadCall == 'deactive') {
      this.phoneModel.dialPadCall = ''
      this.hangupCall()
    }
    console.log(this.phoneModel.mobileNo, 'its mbbbbbb')
    this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back
    var campaignId = ((this.teleSessionData) ? this.teleSessionData['campaignId'] : '');
    console.log(campaignId, "session side nav............")
    this.phoneModel.campaignId = campaignId
    var leadMbNo = ((this.teleSessionData) ? this.teleSessionData['leadMbNo'] : '');
    console.log(leadMbNo, "session leadMbNo side nav............")

    this.phoneModel.leadMbNo = leadMbNo
    let urlRoute = localStorage.getItem('urlRoute');
    console.log(urlRoute, 'it url route')
    let campUrl = '/app/campaign/campaign-details/';
    console.log(this.phoneModel, 'campaignid')
    if (urlRoute.indexOf(campUrl) !== -1) {
      // let mobileNo = this.phoneModel.leadMbNo;
      console.log(leadMbNo, "mobileNo in side nav....")
      // let campaignId = this.phoneModel.campaignId;

      console.log(campaignId, 'campaignid side nav .....')
      this.getSingleCampDetails(campaignId)
      console.log(mobileNo)
      $('#dialPadValue').val(mobileNo);
    } else {
      this.getAllPriList()
    }
    this.getAllPriList()

  }

  getPhoneServerUrl() {
    this.api.getTelephonyConfiguration().subscribe(
      data => {
        console.log(data)
        console.log(data['count'])
        console.log(data['results'])
        if (data['count'] > 0) {
          var domain = ((data['results'][0]['domain']) ? data['results'][0]['domain'] : null);

          if (window.location.hostname.endsWith('localhost')) {
            var http_port = ((data['results'][0]['http_port']) ? data['results'][0]['http_port'] : ' ');
          } else {
            var http_port = ((data['results'][0]['https_port']) ? data['results'][0]['https_port'] : ' ');
          }
          if (http_port != ' ') {
            var url = 'https://' + domain + ':' + http_port + '/'
          } else {
            var url = 'https://' + domain + '/'
          }
          console.log(url)
          this.phoneModel.phoneDomainUrl = url
          localStorage.setItem('phoneUrl', JSON.stringify(url))
          console.log(this.phoneModel.phoneDomainUrl, 'telephony domain')
        }
      })
  }

  getAllPauseCode() {
    const PCodeList = this.api.getAllPauseCode().subscribe((result) => {
      if (result.length > 0) {
        this.pauseCodeArr = result;

        var isNotReady = this.pauseCodeArr.find(item => item.name === 'Not Ready');

        var isNotManualDial = result.find(item => item.name === 'Manual Dial');


        if (isNotReady == undefined) {
          this.createDefaultPauseCode('Not Ready')
        }

        if (isNotManualDial == undefined) {
          this.createDefaultPauseCode('Manual Dial')
        }

      } else {
        this.createDefaultPauseCode('Not Ready')
        this.createDefaultPauseCode('Manual Dial')
      }
    })
  }

  createDefaultPauseCode(name) {
    this.pauseCodeData = {
      name: name,
    };
    this.api.createPauseCode(this.pauseCodeData).subscribe((success) => {
      this.selectedPause = 'Not Ready'
    },);
  }

  getSingleCampDetails(campaignId) {
    var url = localStorage.getItem('urlRoute');
    var isCampPage = url.includes('campaign-details')

    if (isCampPage) {
      this.route.queryParams.subscribe(params => {
        console.log(params)
        campaignId = ((params['selectedCamp']) ? params['selectedCamp'] : '')
      })
    }

    const deleteAccount = this.api.getSingleCampaign(campaignId).subscribe((result) => {

      if (result) {
        this.allPriList = ((result['pris']) ? result['pris'] : '')
        this.priSelected = (this.allPriList[0]) ? (this.allPriList[0]) : ''
      }
    }, (err) => {
      // this.toaster.error("Something went wrong")
    })
  }

  ngAfterViewInit() {
    var acc = document.getElementsByClassName('accordion child');
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].firstElementChild.addEventListener('click', function () {
        this.parentElement.classList.toggle('active');
      });
    }
  }

  toggleMobileSidebar() {
    this.sideNavController.toggle();
  }

  toggleSidebar() {
    console.log('Sidenav toggle');
    this.sideNavController.toggle();
  }
  viewEvent(notification: NotificationRead) {
    let navigateTo = [];
    if (notification.notification_type == NotificationTypes.NOTIFICATION_KM_NEW_ENTRY) {
      let topic = notification.data.topic;
      navigateTo = ['/app/knowledge-management/', topic.id, topic.name];
    }
    else if (notification.notification_type == NotificationTypes.NOTIFICATION_EMAIL_NEW_INCOMING) {
      let threadId = notification.data.id;
      navigateTo = ['/app/email/emails', threadId];

    } else if (notification.notification_type == NotificationTypes.NOTIFICATION_EMAIL_NEW_ASSIGN) {
      let threadId = notification.data.id;
      navigateTo = ['/app/email/emails', threadId]
    }
    else if (notification.notification_type == NotificationTypes.NOTIFICATION_TICKET_NEW_ASSIGN || notification.notification_type == NotificationTypes.NOTIFICATION_TICKET_RESOLVED) {
      let ticketId = notification.data.id;
      navigateTo = ['/app/tickets/ticket-list', ticketId]
    } else if (notification.notification_type == NotificationTypes.NOTIFICATION_LEAD_NEW_ASSIGN) {
      if (notification.data.id && notification.data.campaign_id)
        navigateTo = ['/app/campaign/opportunity-management/', notification.data.campaign_id, notification.data.id];
    } else if (notification.notification_type == NotificationTypes.NOTIFICATION_TICKET_EVENT) {
      let ticketId = notification.data.id;
      navigateTo = ['/app/tickets/ticket-list', ticketId]
    }
    this.socketConnection.clear(notification);
    this.notifications = this.notifications.filter((item) => item.id !== notification.id)

    this.router.navigate(navigateTo);
    console.log(notification)
  }

  ngOnDestroy() {
    // this.pingToSocket.unsubscribe();
    this.phoneStatusInterval && this.phoneStatusInterval.unsubscribe();
    this.phoneModel.callTimerEvent && this.phoneModel.callTimerEvent.unsubscribe();
    this.phoneModel.inboundCall && this.phoneModel.inboundCall.unsubscribe();
    // this.phoneModel.extState && this.phoneModel.extState.unsubscribe();
    this.phoneModel.loginToken && this.phoneModel.loginToken.unsubscribe();
    clearTimeout(this.phoneModel.thresholdTimer);
    localStorage.removeItem('phoneUrl');
  }


  goToHome() {
    if (this.currentUser.tenant.module_permission && this.currentUser.tenant.module_permission.length)
      this.router.navigate([ModuleUrl[this.currentUser.tenant.module_permission[0]]])
    else
      this.router.navigate([noPermissionUrl])
  }
  switchTeamModule() {
    const filterAssigneeDialog = this.switchTeamDialog.open(SwitchTeamDialogComponent, {
      panelClass: 'co-dialog-panel',
      data: this.loginTeam.id
    });

    filterAssigneeDialog.afterClosed().subscribe(user => {

    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    event.preventDefault();
    event.message = "Session progress will be lost, still want to continue ?"
    return false;
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    event.preventDefault();
    event.returnValue = false;

    setTimeout(() => {
      this.logout();
    });

    return false;
  }

  public logout() {
    // localStorage.removeItem('loginToken');

    this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back
    let mobileNo = ((this.phoneModel.mobileNo) ? this.phoneModel.mobileNo : '');
    let agentStatus = ((this.phoneModel.agentStatus) ? this.phoneModel.agentStatus : '');

    // console.log(localStorage.getItem('loginToken'), "My Token")

    if (this.teleSessionData != null) {

      if (mobileNo != '') {
        this.toaster.warning('You are on the call'); return;
      } else {
        if (this.phoneModel.isphoneLogin == 'PAUSE') {
          this.selectPauseCode(1)
        }

        if (this.phoneModel.agentStatus == 'threshold') {
          this.phoneModel.agentStatus = 'ready'
          this.phoneModel.interactionId = ''
          this.teleSessionData['interactionId'] = this.phoneModel.interactionId
          this.teleSessionData['agentStatus'] = this.phoneModel.agentStatus
          localStorage.setItem("phoneData", JSON.stringify(this.teleSessionData));

          setTimeout(() => {
            this.selectPauseCode(1);
          }, 1000);

          clearTimeout(this.phoneModel.thresholdTimer);
        }

        setTimeout(() => {
          this.telephonyLogout();
          this.crmLogout();
        });
      }


    } else {
      this.api.logout().subscribe((data) => {
        console.log(data)
        this.router.navigate([loginUrl]);
      })
      this.socketConnection.disconnect();
      this.auth.logout();
    }
  }

  crmLogout() {
    this.api.logout().subscribe((data) => {
      console.log(data)
      this.router.navigate([loginUrl]);
    })
    this.socketConnection.disconnect();
    this.auth.logout();
  }

  refreshPage() {
    location.reload();
  }

  /* Start telephony functions */

  telephonyInit() {
    $('#phoneLogout').show();
    $('#phoneLogin').hide();

    this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back
    console.log(this.teleSessionData)

    var agentStatus = ((this.teleSessionData) ? this.teleSessionData['agentStatus'] : '');
    this.phoneModel.agentStatus = agentStatus

    var callId = ((this.teleSessionData) ? this.teleSessionData['callId'] : '');
    this.phoneModel.callId = callId

    var mobileNo = ((this.teleSessionData) ? this.teleSessionData['mobileNo'] : '');
    this.phoneModel.mobileNo = mobileNo

    var confNo = ((this.teleSessionData) ? this.teleSessionData['secondNo'] : '');

    var loginStatus = ((this.teleSessionData) ? this.teleSessionData['isPhoneLogin'] : '');
    this.phoneModel.isphoneLogin = loginStatus

    var sessionExt = ((this.teleSessionData) ? this.teleSessionData['extension'] : '');
    this.phoneModel.extension = sessionExt

    var sessionId = ((this.teleSessionData) ? this.teleSessionData['sessionId'] : '');
    this.phoneModel.phoneSessionId = sessionId

    var loginQueue = ((this.teleSessionData) ? this.teleSessionData['queues'] : []);
    this.phoneModel.loginCampaign = loginQueue

    var breakId = ((this.teleSessionData) ? this.teleSessionData['breakId'] : '');
    this.phoneModel.breakId = breakId

    var uniqueId = ((this.teleSessionData) ? this.teleSessionData['uniqueId'] : '');
    this.phoneModel.uniqueId = uniqueId

    var interactionId = ((this.teleSessionData) ? this.teleSessionData['interactionId'] : '');
    this.phoneModel.interactionId = interactionId

    var campaignId = ((this.teleSessionData) ? this.teleSessionData['campaignId'] : '');
    this.phoneModel.campaignId = campaignId

    var leadMbNo = ((this.teleSessionData) ? this.teleSessionData['leadMbNo'] : '');
    this.phoneModel.leadMbNo = leadMbNo

    var pauseCodeVal = ((this.teleSessionData) ? this.teleSessionData['selectedPauseCode'] : '');
    this.phoneModel.selectedPauseCode = pauseCodeVal
    this.selectedPause = this.phoneModel.selectedPauseCode

    var disposCodeVal = ((this.teleSessionData) ? this.teleSessionData['disposRemark'] : '');
    this.phoneModel.disposCode = disposCodeVal

    var phone_loin_type = ((this.teleSessionData) ? this.teleSessionData['phone_loin_type'] : '');
    this.phoneModel.phone_loin_type = phone_loin_type



    var mobileNo = $('#dialPadValue').val(mobileNo);
    $('#conCallValue').val(confNo);

    let isLogin = ((this.phoneModel.isphoneLogin) ? this.phoneModel.isphoneLogin : 'NO');

    console.log(isLogin)
    if (isLogin == 'YES') {
      this.phoneLogout = false;
      this.navPhoneLogout = true;
      this.phoneLogin = false;
      this.phoneOnPause = true;
      this.navPhoneLogin = false;
      this.resumeBtn = false;
      this.allPauseCode = false;
      this.pauseBtn = true;
      this.pauseCodeText = true;
      // this.selectedPause = 'Not Active'
    } else if (isLogin == 'PAUSE') {
      this.phoneLogout = false;
      this.navPhoneLogout = true;
      this.phoneOnPause = true;
      this.navPhoneLogin = false;
      this.resumeBtn = false;
      this.allPauseCode = false;
      this.pauseBtn = true;
      this.pauseCodeText = true;
    } else if (isLogin == 'RESUME') {
      this.phoneLogout = false;
      this.navPhoneLogout = true;
      this.phoneLogin = true;
      this.navPhoneLogin = false;
      this.resumeBtn = true;
      this.allPauseCode = true;
      this.pauseBtn = false;
      this.pauseCodeText = false;
      this.checkInboundCall()
    }

    console.log(agentStatus)
    if (agentStatus == 'incall') {
      this.showIcons(true, false, true, false, true, true, true, true, true, false, false, false, false,
        false, false, false, false, false)
      this.priBox = false
      console.log(this.phoneModel.callTimerEvent, 'ng on init timerrrryyysss')
      console.log('in ngOnInit timer')
      this.timer()
      this.reStartExtCheck()
      this.phoneModel.inboundCall && this.phoneModel.inboundCall.unsubscribe();
    } else if (agentStatus == 'acw') {
      this.disposObCall()
    } else if (agentStatus == 'ready') {
      this.showPhoneIcons()
    } else if (agentStatus == 'hold') {
      this.callParkFunc()
      this.timer()
      this.reStartExtCheck()
    } else if (agentStatus == 'retrieve') {
      this.callUnParkFunc()
      this.timer()
      this.reStartExtCheck()
    } else if (agentStatus == 'callConf') {
      this.showIcons(true, false, true, false, false, true, false, false, false, true, false, true, true,
        true, false, false, false, false)
      $('#dialPadValue').val('ONCONFERENCE');
      this.timer()
      this.reStartExtCheck()
      this.priBox = false
    } else if (agentStatus == 'threshold') {
      clearTimeout(this.phoneModel.thresholdTimer);

      this.setThreshold()
    }

    if (isLogin == 'PAUSE') {
      this.resumeBtn = false;
      this.pauseBtn = true;
      this.allPauseCode = false;
    }
  }

  reStartExtCheck() {
    this.phoneModel.extState && this.phoneModel.extState.unsubscribe();

    console.log(this.phoneModel.extState)
    // if (this.phoneModel.extState == undefined) {
    setTimeout(() => {
      this.checkExtensionState()
    }, 3000)
    // }
  }

  openDialPad() {

    this.api.getTelephonyConfiguration().subscribe(
      data => {
        if (data['count'] > 0) {
          const dialPadDialog = this.dialPadDialog.open(DialpadComponent, {
            panelClass: 'co-dialog-panel',
            data: { bulkUpload: true },
            position: { left: '230px', top: '50px' }

          });
        } else {
          this.toaster.warning('Telephony server not configured'); return;
        }
      })
  }

  getTelephonyConfiguration() {
    this.api.getTelephonyConfiguration().subscribe(
      data => {
        if (data['count'] > 0) {
          this.isTelephonyLogin()
        } else {
          this.toaster.warning('Telephony server not configured'); return;
        }
      })
  }

  isTelephonyLogin() {
    let outboundId = this.phoneModel.interactionId
    if (!outboundId) {
      this.getLoginAgentPhoneData()
    } else {
      this.toaster.warning('You are on the outbound call'); return;
    }
  }



  getLoginAgentPhoneData() {
    var user_id = ((this.currentUser['id']) ? this.currentUser['id'] : null);
    let extension = ((this.currentUser['user_telephony_extension'][0]) ? this.currentUser['user_telephony_extension'][0] : null);

    if (extension == null) {
      this.toaster.warning('Extension not mapped to this user.');
      return;
    }

    this.api.getLoginAgentPhoneData(user_id).subscribe((result) => {
      if (result.length > 0) {
        var phone_loin_type = ((result[0]['call_type']) ? result[0]['call_type'] : null);

        if (phone_loin_type == 'inbound') {
          var queues = ((result[0]['queues']) ? result[0]['queues'] : []);
          if (queues.length > 0) {
            this.loginInTelephony(queues, extension, phone_loin_type)
          } else {
            this.toaster.warning('Queue not mapped to this user.');
            return;
          }
        } else if (phone_loin_type == 'autodial') {
          var mapped_campaigns = ((result[0]['mapped_campaigns']) ? result[0]['mapped_campaigns'] : []);

          if (mapped_campaigns.length > 0) {
            this.loginInTelephony(mapped_campaigns, extension, phone_loin_type)
          } else {
            this.toaster.warning('Campaign not mapped to this user.');
            return;
          }
        } else {
          this.toaster.warning('Please map queue /campaign  to this user.');
          return;
        }

      } else {
        this.toaster.warning('Queue /Campaign not mapped to this user.');
        return;
      }

    })
  }

  loginInTelephony(queues, extension, phone_loin_type) {
    localStorage.removeItem('phoneData')

    var username = ((this.currentUser['username']) ? this.currentUser['username'] : null);


    let queueLength = queues.length - 1

    this.phoneData = {
      campaigns: '',
    };

    queues.forEach(element => {
      let action = 'add';

      if (phone_loin_type == 'inbound') {
        var queue = ((element['name']) ? element['name'] : null);
      } else {
        var queue = ((element['id']) ? element['id'] : null);
      }

      let penalty = ((element['penalty']) ? element['penalty'] : 0);

      if (this.phoneData.campaigns != '') {
        this.phoneData.campaigns = `${this.phoneData.campaigns},${queue}`
      } else {
        this.phoneData.campaigns = queue
      }

      let logoutData = { 'extension': extension, 'action': 'remove', 'queue': queue, 'username': '', 'penalty': 0 }

      this.phoneApi.phoneLoginLogout(logoutData).subscribe(
        data => {
        })

      setTimeout(() => {
        let phoneData = { 'extension': extension, 'action': action, 'queue': queue, 'username': username, 'penalty': penalty }

        this.phoneApi.phoneLoginLogout(phoneData).subscribe(
          data => {
            if (data) {
              let msg = data['Desc'].toString();
              if (msg != null || msg != '' || msg != undefined) {
                if (msg.indexOf('Success') !== -1) {

                  if (queues[queueLength]['name'] == element['name']) {

                    this.phoneLogout = false;
                    this.navPhoneLogout = true;
                    this.phoneLogin = false;
                    this.phoneOnPause = true;
                    this.navPhoneLogin = false;
                    this.resumeBtn = false;
                    this.allPauseCode = false;
                    this.pauseBtn = true;
                    this.pauseCodeText = true;
                    this.selectedPause = 'Not Ready'


                    /*temporaray data store in the model file */
                    this.phoneModel.extension = ((phoneData['extension']) ? phoneData['extension'] : null);
                    this.phoneModel.isphoneLogin = 'YES';
                    this.phoneModel.loginCampaign = queues
                    this.phoneModel.agentStatus = 'unpause'
                    this.phoneModel.phone_loin_type = phone_loin_type

                    setTimeout(() => {
                      this.selectPauseCode(0)
                    }, 1000)
                    /*data store in the session */

                    var telData = {
                      'extension': this.phoneModel.extension, 'isPhoneLogin': 'YES',
                      'queues': this.phoneModel.loginCampaign, 'agentStatus': this.phoneModel.agentStatus,
                      'sessionId': '', 'callId': '', 'mobileNo': '', 'breakId': '', 'holdId': '', 'secondNo': '',
                      'uniqueId': '', 'interactionId': '', 'phone_loin_type': phone_loin_type
                    };

                    localStorage.setItem("phoneData", JSON.stringify(telData));

                    this.addPhoneLoginLogout(this.phoneData)

                    this.toaster.success('Telephony login successfully.')
                  }
                } else {
                  this.toaster.error('Some thing went wrong in telephony login functionality.');
                  return;
                }
              } else {
                this.toaster.error('Telephony server not responding.');
                return;
              }
            } else {
              this.toaster.warning('Telephony login failed'); return;
            }

          },
          error => {
            this.toaster.error('Some thing went wrong in telephony login functionality.');
            return;
          });
      }, 1000)


    });
  }

  // openTelephonyLoginView() {
  //   const dialPadDialog = this.telephonyLoginDialog.open(TelephonyLoginComponent, {
  //     panelClass: 'co-dialog-panel',
  //     data: { bulkUpload: true, notificationData: this.notifications },
  //     width: '336px',
  //     // height: '107px'
  //   });
  // }

  phoneLogoutFunc() {
    let mobileNo = ((this.phoneModel.mobileNo) ? this.phoneModel.mobileNo : '');
    this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back

    if (mobileNo != '') {
      this.toaster.warning('You are on the call'); return;
    } else {
      if (this.phoneModel.isphoneLogin == 'PAUSE') {
        this.selectPauseCode(1)
      }

      if (this.phoneModel.agentStatus == 'threshold') {
        this.phoneModel.agentStatus = 'ready'
        this.phoneModel.interactionId = ''
        this.teleSessionData['interactionId'] = this.phoneModel.interactionId
        this.teleSessionData['agentStatus'] = this.phoneModel.agentStatus
        localStorage.setItem("phoneData", JSON.stringify(this.teleSessionData));

        setTimeout(() => {
          this.selectPauseCode(1)
        }, 1000)

        clearTimeout(this.phoneModel.thresholdTimer);
      }

      setTimeout(() => {
        this.telephonyLogout()
      }, 2000)

      this.resumeBtn = false;
      this.pauseCodeText = false;
      this.pauseBtn = false;
      this.allPauseCode = false;
    }

  }

  telephonyLogout() {

    var id = ((this.currentUser['id']) ? this.currentUser['id'] : null);
    var username = ((this.currentUser['username']) ? this.currentUser['username'] : null);

    let phoneLogin = ((this.phoneModel.extension) ? this.phoneModel.extension : '');
    let queue = ((this.phoneModel.loginCampaign) ? this.phoneModel.loginCampaign : '');
    let loginType = ((this.phoneModel.phone_loin_type) ? this.phoneModel.phone_loin_type : '');


    let action = 'remove';
    if (queue.length > 0) {
      let logoutData = { 'action': 'logout', 'logout_reason': 'Self Logout' }

      let queueLength = queue.length - 1;

      queue.forEach(element => {
        if (loginType == 'inbound') {
          var qVal = ((element['name']) ? element['name'] : null);
        } else {
          var qVal = ((element['id']) ? element['id'] : null);
        }

        let phoneData = { 'extension': phoneLogin, 'action': action, 'queue': qVal }

        this.phoneApi.phoneLoginLogout(phoneData).subscribe(
          data => {
            let msg = data['Desc'].toString();
            if (msg != null || msg != '' || msg != undefined) {
              if (msg.indexOf("Success") !== -1) {
                this.showIcons(false, false, false, true, false, false, false, false, true, false, false, false, false,
                  false, false, true, false, false)
                this.navPhoneLogin = true;
                this.navPhoneLogout = false;
                this.phoneModel.agentStatus = ''
                $('#callTimer').val('00:00:00');
                this.phoneModel.callStatus = ''
                this.phoneModel.loginCampaign = []
                this.phoneModel.phone_loin_type = ''
                this.resumeBtn = false;
                this.pauseCodeText = false;
                this.pauseBtn = false;
                this.allPauseCode = false;
                this.phoneOnPause = false;
                this.phoneLogin = false
                if (queue[queueLength]['name'] == element['name']) {
                  this.addLogoutEntry(logoutData)
                }

                this.phoneModel.inboundCall && this.phoneModel.inboundCall.unsubscribe();

              }
            } else {
              this.toaster.error('Telephony server not responding.');
              return;
            }
          },
          error => {
            this.toaster.error('Error in telephony logout function.');
            return;
          });
      });
      this.toaster.success('Telephony logout successfully');
    }


  }

  selectPauseCode(flag) {
    let phoneLogin = ((this.phoneModel.extension) ? this.phoneModel.extension : '');
    let queue = ((this.phoneModel.loginCampaign) ? this.phoneModel.loginCampaign : []);
    this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back
    var userId = ((this.currentUser['id']) ? this.currentUser['id'] : null);
    let mobileNo = ((this.phoneModel.mobileNo) ? this.phoneModel.mobileNo : '');
    let agentStatus = ((this.phoneModel.agentStatus) ? this.phoneModel.agentStatus : '');
    let loginType = ((this.phoneModel.phone_loin_type) ? this.phoneModel.phone_loin_type : []);


    if (phoneLogin == 0 || phoneLogin == '' || phoneLogin == undefined) {
      this.toaster.error('Please login in telephony'); return;
    }
    if (mobileNo != '') {
      this.toaster.warning('You can not change state on the live call'); return;
    }
    if (agentStatus == 'threshold') {
      this.toaster.warning('You can not change state on the threshold time'); return;
    }

    if (flag == 0) {
      if (this.selectedPause == null || this.selectedPause == '') {
        this.toaster.warning('Please select pause code'); return;
      }

      var action = 'pause';
    } else {
      var action = 'unpause';
    }

    let queueLength = queue.length - 1;
    if (queue.length > 0) {
      queue.forEach(element => {

        if (loginType == 'inbound') {
          var qVal = ((element['name']) ? element['name'] : null);
        } else if (loginType == 'autodial') {
          var qVal = ((element['id']) ? element['id'] : null);
        } else {
          var qVal = ((element['name']) ? element['name'] : null);
        }

        let phoneData = { 'extension': phoneLogin, 'action': action, 'queue': qVal, 'pauseCode': this.selectedPause, 'breakId': this.phoneModel.breakId }

        this.phoneApi.phonePauseUnpause(phoneData).subscribe(
          data => {
            let msg = data['Desc'].toString();
            if (msg != null || msg != '' || msg != undefined) {
              if (msg.indexOf("Success") !== -1) {
                if (queue[queueLength]['name'] == element['name']) {

                  if (flag == 0) {
                    this.breakData = {
                      user_id: userId,
                      break_remark: this.selectedPause,
                    };
                    this.addBreakTime(this.breakData)

                    this.phoneModel.isphoneLogin = 'PAUSE'
                    this.phoneModel.agentStatus = 'pause'
                    this.phoneModel.selectedPauseCode = this.selectedPause
                    this.teleSessionData['selectedPauseCode'] = this.phoneModel.selectedPauseCode
                    this.teleSessionData['phone_loin_type'] = 'outbound'
                    this.teleSessionData['isPhoneLogin'] = 'PAUSE'
                    this.teleSessionData['agentStatus'] = 'pause'
                    localStorage.setItem("phoneData", JSON.stringify(this.teleSessionData));
                    this.phoneModel.inboundCall && this.phoneModel.inboundCall.unsubscribe();
                  } else {
                    this.resumeData = {
                      action: 'resume',
                    };
                    this.selectedPause = ''
                    this.phoneModel.selectedPauseCode = this.selectedPause
                    this.phoneModel.isphoneLogin = 'RESUME'
                    this.phoneModel.agentStatus = 'ready'
                    this.addResumeEntry(this.resumeData)
                    this.teleSessionData['selectedPauseCode'] = ''
                    this.teleSessionData['isPhoneLogin'] = 'RESUME'
                    this.teleSessionData['phone_loin_type'] = 'inbound'
                    this.teleSessionData['agentStatus'] = 'ready'
                    localStorage.setItem("phoneData", JSON.stringify(this.teleSessionData));
                    this.checkInboundCall()
                  }
                  this.activeButtons(flag, action)

                }
              } else {
                this.toaster.error('Telephony server not responding.');
                return;
              }

            }
            error => {
              this.toaster.error('Error in ' + action + ' functionality.');
              return;
            }
          });
      });


    } else {
      this.toaster.warning('Please login in the telephony');
      return;
    }

  }

  activeButtons(flag, action) {

    let phoneLogin = ((this.phoneModel.extension) ? this.phoneModel.extension : '');

    this.extStateData = {
      extension: phoneLogin,
      state: action,
    };

    this.addExtState(this.extStateData)

    if (flag == 0) {

      this.phoneModel.inboundCall && this.phoneModel.inboundCall.unsubscribe();

      if (this.selectedPause == 'Manual Dial') {
        this.toaster.success('Status changed to "Manual Dial" successfully.')
      } else {
        this.toaster.success('Pause successfully');
      }
      this.phoneModel.phone_loin_type = 'outbound'
      this.resumeBtn = false;
      this.pauseCodeText = true;
      this.pauseBtn = true;
      this.allPauseCode = false;
      this.phoneOnPause = true;
      this.phoneLogin = false

    } else {

      this.toaster.success('Resume successfully');
      this.phoneModel.phone_loin_type = 'inbound'
      this.resumeBtn = true;
      this.pauseCodeText = false;
      this.pauseBtn = false;
      this.allPauseCode = true;
      this.phoneOnPause = false;
      this.phoneLogin = true;

    }

  }

  acwState() {
    let phoneLogin = ((this.phoneModel.extension) ? this.phoneModel.extension : 0);
    let queue = ((this.phoneModel.loginCampaign) ? this.phoneModel.loginCampaign : '');
    var action = 'pause';
    var userId = ((this.currentUser['id']) ? this.currentUser['id'] : null);
    let outboundId = this.phoneModel.interactionId

    if (!outboundId) {
      if (queue.length > 0) {
        this.breakData = {
          user_id: userId,
          break_remark: 'ACW',
        };

        this.addBreakTime(this.breakData)

        queue.forEach(element => {
          var qVal = ((element['name']) ? element['name'] : null);

          let phoneData = { 'extension': phoneLogin, 'action': action, 'queue': qVal, 'pauseCode': 'ACW' }


          this.phoneApi.phonePauseUnpause(phoneData).subscribe(
            data => {
              let msg = data['Desc'].toString();
            },
            error => {
              this.toaster.error('Some thing went wrong in pause functionality.');
              return;
            });
        });

      } else {
        this.toaster.error('Error in queue selection');
        return;
      }
    }

  }


  openHelpWindow() {
    window.open("src/assets/pdf/support.pdf")
  }

  beforeDial() {
    let queue = ((this.phoneModel.loginCampaign) ? this.phoneModel.loginCampaign : []);

    this.api.getTelephonyConfiguration().subscribe(
      data => {
        if (data['count'] > 0) {

          if (queue.length == 0) {
            this.toaster.error('Please login to telephony'); return;
          }

          // let mobileNo = $('#dialPadValue').val();
          let mobileNo = this.dialInputValue ? this.dialInputValue : this.unMaskedDialPadValue;
          // let mobileNo = this.unMaskedDialPadValue ? this.unMaskedDialPadValue : $('#dialPadValue').val();
          let mobileNoLen = mobileNo.toLocaleString().length;
          if (mobileNoLen < 10) {
            this.toaster.error('Please enter valid number'); return;
          }

          setTimeout(() => {
            this.dial()
          }, 1000)

        } else {
          this.toaster.warning('Telephony server not configured'); return;
        }
      })
  }

  dial() {
    this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back

    if (this.teleSessionData == null) {
      var telData = {
        'extension': '', 'isPhoneLogin': '',
        'queues': '', 'agentStatus': '',
        'sessionId': '', 'callId': '', 'mobileNo': '', 'breakId': '', 'holdId': '', 'secondNo': '',
        'uniqueId': '', 'interactionId': ''
      };

      localStorage.setItem("phoneData", JSON.stringify(telData));
    }

    this.phoneModel.extension = ((this.currentUser['user_telephony_extension'][0]) ? this.currentUser['user_telephony_extension'][0] : '');


    let phoneLogin = ((this.phoneModel.extension) ? this.phoneModel.extension : '');
    var agentStatus = ((this.phoneModel.agentStatus) ? this.phoneModel.agentStatus : '');
    let queue = ((this.phoneModel.loginCampaign) ? this.phoneModel.loginCampaign : []);
    var userId = ((this.currentUser['id']) ? this.currentUser['id'] : null);


    if (this.phoneModel.isphoneLogin != 'NO') {
      if (agentStatus == 'ready' && queue.length > 0) {
        this.toaster.warning('Please select "Manual Dial" to make an Outbound Call.'); return;
      }
    }

    if (agentStatus == 'threshold') {
      this.toaster.warning('Please wait for dial because you are on threshold time'); return;
    }

    if (this.selectedPause != 'Manual Dial') {
      this.toaster.warning('Please select "Manual Dial" to make an Outbound Call.'); return;
    }

    if (!this.priSelected) {
      this.toaster.warning('Please set PRI No for dial'); return;
    } else {
      let prnNo = ((this.priSelected['phone_no']) ? this.priSelected['phone_no'] : 0);
      let cCode = this.priSelected['country_code'];
      let sCode = ((this.priSelected['std_code']) ? this.priSelected['std_code'] : '');
      if (prnNo != 0) {
        if (prnNo < 10) {
          this.priNumber = prnNo;
        } else {
          this.priNumber = prnNo;
        }
      } else {
        this.toaster.warning('Please set PRI No for dial'); return;
      }
    }
    // let mobileNo = $('#dialPadValue').val();
    let mobileNo = this.dialInputValue ? this.dialInputValue : this.unMaskedDialPadValue;
    // let mobileNo = this.unMaskedDialPadValue ? this.unMaskedDialPadValue : $('#dialPadValue').val();
    let mobileNoLen = mobileNo.toLocaleString().length;
    if (mobileNoLen < 10) {
      this.toaster.error('Please enter valid number'); return;
    }

    var interactionId = new Date().getFullYear().toString() + '' + '0' + (new Date().getMonth() + 1).toString().slice(-2) + '' + new Date().getDate().toString() + '' + new Date().getHours() + '' + new Date().getMinutes() + '' + new Date().getSeconds();
    var interactionid = interactionId;

    this.phoneModel.interactionId = interactionid


    this.obcallData = {
      user_id: userId,
      called_from_phone_no: this.priNumber,
      customer_phone_no: Number(mobileNo),
      // customer_phone_no: mobileNo,
      call_type: 'outbound',
      campaign_name: 'manual',
      outbound_account_code: this.phoneModel.interactionId,
      extension_no: phoneLogin
    };

    this.extStateData = {
      extension: phoneLogin,
      state: 'busy',
    };


    this.phoneApi.dial(phoneLogin, mobileNo, this.priNumber, interactionid).subscribe(
      data => {

        let msg = data['Desc'].toString();
        if (msg != null || msg != '' || msg != undefined) {
          if (msg.indexOf("Success") !== -1) {
            this.searchCustomer(mobileNo)
            this.addCallEntry(this.obcallData)
            this.addExtState(this.extStateData)

            // this.phoneModel.extState && this.phoneModel.extState.unsubscribe();


            setTimeout(() => {
              this.checkExtensionState()
            }, 2000)

            this.phoneModel.mobileNo = mobileNo;
            this.toaster.success('Placing call to : ' + ' ' + mobileNo);
            this.phoneModel.agentStatus = 'incall'

            this.teleSessionData['extension'] = this.phoneModel.extension
            this.teleSessionData['agentStatus'] = this.phoneModel.agentStatus
            this.teleSessionData['mobileNo'] = this.phoneModel.mobileNo
            this.teleSessionData['interactionId'] = this.phoneModel.interactionId
            localStorage.setItem("phoneData", JSON.stringify(this.teleSessionData));

            this.timer()
            this.showIcons(true, false, true, false, true, true, true, true, true, false, false, false, false,
              false, false, false, false, false)
            this.priBox = false
          }
        } else {
          this.toaster.error('Telephony server not responding.');
          return;
        }
      },
      error => {
        this.toaster.error('Error in dial functionality.');
        return;
      }
    );
  }
  searchCustomer(mobileNo) {
    let url = localStorage.getItem('urlRoute');
    if (url.indexOf('campaign-details') == -1) {
      this.api.searchCustomer(mobileNo).subscribe(
        data => {
          if (data.length > 0) {
            if (data.length == 1) {
              let contactId = ((data[0]['id']) ? data[0]['id'] : 0);
              this.router.navigate(['/app/customers/contact', contactId, 'edit'])
            } else {
              this.phoneModel.contactData = data
              this.router.navigate(['/app/customers']);
            }
          }
        });
    }
  }

  checkExtensionState() {
    let parkFlag = this.phoneModel.callStatus
    let currentUrl = localStorage.getItem('urlRoute');


    this.phoneModel.extState && this.phoneModel.extState.unsubscribe();
    const source = interval(5000);
    this.phoneModel.extState = source.subscribe(val => { this.checkExtensionState(); })

    let phoneLogin = ((this.phoneModel.extension) ? this.phoneModel.extension : 0);

    this.api.checkExtensionState(phoneLogin).subscribe(
      data => {
        console.log(data)
        let extStatus = ((data['state']) ? data['state'] : '');
        this.phoneModel.extStatus = extStatus
        console.log(extStatus)
        if (extStatus == 'available') {

          this.phoneModel.extState && this.phoneModel.extState.unsubscribe();

          if (parkFlag == 'park') {
            this.holdData = {
              action: 'resume-call',
            };
            this.addHoldRetrieveEntry(this.holdData)
          }

          this.extStateData = {
            extension: phoneLogin,
            state: 'acw',
          };

          this.addExtState(this.extStateData)

          $('#callTimer').val('00:00:00');

          this.phoneModel.callTimerEvent && this.phoneModel.callTimerEvent.unsubscribe();

          this.phoneModel.callStatus = ''

          setTimeout(() => {
            this.hangupCall()
          }, 3000)


          if (this.phoneModel.ibCallPopup) {
            console.log('automatic close')
            this.phoneModel.ibCallPopup.close()
          }
        }
      });
  }

  addCallEntry(callData) {
    this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back

    this.api.addAgentCallEntry(callData).subscribe(
      data => {
        let callId = ((data['id']) ? data['id'] : '');
        if (callId != '') {
          let callId = ((data['id']) ? data['id'] : '');
          console.log(callId)
          this.phoneModel.callId = callId
          this.teleSessionData['callId'] = callId
          localStorage.setItem("phoneData", JSON.stringify(this.teleSessionData));
          console.log(this.teleSessionData)
        }
      });
  }

  addExtState(extStateData) {
    this.api.addAgentExtState(extStateData).subscribe(
      data => {
      });
  }

  addDisposEntry(callData) {
    console.log(callData)
    let callId = this.phoneModel.callId;
    this.api.addAgentCallDisposEntry(callData, callId).subscribe(
      data => {
        if (data.length > 0) {
        }
      });
  }

  addBreakTime(breakData) {
    this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back

    this.api.addBreakTime(breakData).subscribe(
      data => {
        let breakId = ((data['id']) ? data['id'] : '');
        console.log(breakId)
        if (breakId != '') {
          let breakId = ((data['id']) ? data['id'] : '');
          console.log(breakId)
          this.phoneModel.breakId = breakId
          this.teleSessionData['breakId'] = breakId
          localStorage.setItem("phoneData", JSON.stringify(this.teleSessionData));
        }
      });
  }

  addResumeEntry(breakData) {
    let breakId = this.phoneModel.breakId;
    this.api.addAgentResumeEntry(breakData, breakId).subscribe(
      data => {
        if (data.length > 0) {
        }
      });
  }

  addHoldRetrieveEntry(holdData) {
    let callId = this.phoneModel.callId;
    this.api.addHoldRetrieveEntry(holdData, callId).subscribe(
      data => {
      });
  }

  addHangupEntry(callData) {
    let callId = this.phoneModel.callId;
    this.api.addAgentCallHangupEntry(callData, callId).subscribe(
      data => {
        if (data.length > 0) {
        }
      });
  }

  addLogoutEntry(logoutData) {
    let phoneSessionId = this.phoneModel.phoneSessionId;
    console.log(phoneSessionId)
    this.api.addAgentLogoutEntry(logoutData, phoneSessionId).subscribe(
      data => {
        this.phoneModel.isphoneLogin = 'NO';
        localStorage.removeItem('phoneData')
        if (data.length > 0) {
        }
      });
  }


  hangupCall() {


    this.showIcons(false, false, false, true, false, false, false, false, true, false, false, false,
      false, false, false, false, true, false)

    this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back

    let queue = ((this.phoneModel.loginCampaign) ? this.phoneModel.loginCampaign : []);
    let phoneLogin = ((this.phoneModel.extension) ? this.phoneModel.extension : '');
    let parkFlag = this.phoneModel.callStatus
    let phoneData = { 'extension': phoneLogin }
    let callHangupData = { 'action': 'end-call' }

    if (parkFlag == 'park' || parkFlag == 'confPark') {
      this.toaster.warning('Please unpark customer'); return;
    } else {

      console.log(this.phoneModel.extState, 'extState')
      this.phoneModel.extState && this.phoneModel.extState.unsubscribe();

      setTimeout(() => {
        this.phoneApi.phoneHangup(phoneData).subscribe(
          data => {
            let msg = data['Desc'].toString();
            if (msg != null || msg != '' || msg != undefined) {
              if (msg.indexOf("Success") !== -1) {
                this.showIcons(false, false, false, true, false, false, false, false, true, false, false, false,
                  false, false, false, false, true, false)

                $('#callTimer').val('00:00:00');

                this.phoneModel.callTimerEvent && this.phoneModel.callTimerEvent.unsubscribe();


                this.toaster.success('Call disconnected');

                this.priBox = true
                $('#conCallValue').val('');
                //$('#dialPadValue').val('');
                this.phoneModel.agentStatus = 'acw';
                this.phoneModel.mobileNo = '';
                this.phoneModel.contactData = ''

                this.teleSessionData['agentStatus'] = this.phoneModel.agentStatus
                this.teleSessionData['mobileNo'] = this.phoneModel.mobileNo
                localStorage.setItem("phoneData", JSON.stringify(this.teleSessionData));
                console.log(this.phoneModel.agentStatus, 'hangup call with acw')
                if (this.phoneModel.agentStatus == 'acw') {
                  this.disposObCall()
                }


                this.addHangupEntry(callHangupData)
                if (queue.length > 0) {
                  this.acwState()
                }
              } else if (msg.indexOf("ERROR") !== -1) {
                this.toaster.error('Phone error : ' + msg);
              }
            } else {
              this.toaster.error('Telephony server not responding.');
              return;
            }
          },
          error => {
            this.showIcons(true, false, true, false, true, true, true, true, true, false, false, false, false,
              false, false, false, false, false)
            this.toaster.error('Error in hangup functionality.');
            return;
          });
      }, 2000)


    }

  }

  disposObCall() {

    this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back

    let loginStatus = ((this.phoneModel.isphoneLogin) ? this.phoneModel.isphoneLogin : '');
    let phoneLogin = ((this.phoneModel.extension) ? this.phoneModel.extension : '');

    console.log(loginStatus)
    if (loginStatus == 'RESUME') {
      this.checkInboundCall()
    }
    this.extStateData = {
      extension: phoneLogin,
      state: 'acw',
    };

    this.phoneModel.callStatus = ''
    this.addExtState(this.extStateData)
    this.telephonyDispositonDialog()
    this.showIcons(false, false, false, true, false, false, false, false, false, false, false, false, false,
      false, false, false, true, false)

    this.phoneModel.agentStatus = 'acw'
  }


  parkUnparkCall(flag) {

    let phoneLogin = ((this.phoneModel.extension) ? this.phoneModel.extension : '');

    if (flag == 1) {
      this.parkCustomer = 'hold';
    } else {
      this.parkCustomer = 'retrieve';
    }
    var mobileNo = ((this.phoneModel.mobileNo) ? this.phoneModel.mobileNo : '')
    $('#dialPadValue').val(mobileNo);
    let phoneData = { 'extension': phoneLogin, 'action': this.parkCustomer, 'mobileNo': mobileNo }
    this.extStateData = {
      extension: phoneLogin,
      state: this.parkCustomer,
    };


    this.phoneApi.phoneHoldRetrieve(phoneData).subscribe(
      data => {
        if (data != null || data != '' || data != undefined) {
          let msg = data['Desc'].toString();
          if (msg != null || msg != '' || msg != undefined) {
            if (msg.indexOf('Success') !== -1) {
              if (flag == 1) {
                this.callParkFunc()
                this.holdData = {
                  action: 'hold-call',
                };
                this.addHoldRetrieveEntry(this.holdData)
                // setTimeout(() => {
                //   if (!this.phoneModel.extState) {
                //     this.checkExtensionState()
                //   }
                // }, 3000)

                this.addExtState(this.extStateData)
              } else {
                this.holdData = {
                  action: 'resume-call',
                };
                this.addHoldRetrieveEntry(this.holdData)
                this.callUnParkFunc()
                this.addExtState(this.extStateData)
              }

              this.phoneModel.agentStatus = this.parkCustomer
              this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back
              this.teleSessionData['agentStatus'] = this.phoneModel.agentStatus
              localStorage.setItem("phoneData", JSON.stringify(this.teleSessionData));
            }
            else {
              this.toaster.error(' Error in telephony park functionality.');
              return;
            }
          }
          else {
            this.toaster.error('Telephony server not responding.');
            return;
          }
        }
        else {
          this.toaster.error('Telephony server not responding.');
          return;
        }
      });
  }

  callParkFunc() {
    if (this.phoneModel.callStatus == 'callConf') {
      this.showIcons(true, false, false, false, false, true, false, false, true, false, true, true, true,
        true, false, false, false, false)
      $('#dialPadValue').val('ONCONFERENCE');
      this.phoneModel.callStatus = 'confPark';
    } else if (this.phoneModel.callStatus == 'confUnpark') {
      this.showIcons(true, false, true, false, false, true, false, false, true, false, false, true, true,
        true, false, false, false, false)
      $('#dialPadValue').val('ONCONFERENCE');
      this.phoneModel.callStatus = 'confPark';
    }
    else {
      this.showIcons(true, false, false, false, true, true, true, true, true, false, true, false, false,
        false, false, false, false, false)
      this.phoneModel.callStatus = 'park';
    }
    this.priBox = false

    this.toaster.success('Call parked successfully.');
  }

  callUnParkFunc() {
    if (this.phoneModel.callStatus == 'callConf') {
      this.showIcons(true, false, true, false, true, true, false, false, true, false, false, true, true,
        true, false, false, false, false)
      $('#dialPadValue').val('ONCONFERENCE');
      this.phoneModel.callStatus = 'confUnpark';
    } else if (this.phoneModel.callStatus == 'confPark') {
      this.showIcons(true, false, true, false, false, true, false, false, true, false, false, true, true,
        true, false, false, false, false)
      $('#dialPadValue').val('ONCONFERENCE');
      this.phoneModel.callStatus = 'confUnpark';
    }
    else {
      this.showIcons(true, false, true, false, true, true, true, true, true, false, false, false, false,
        false, false, false, false, false)
      this.phoneModel.callStatus = 'unpark';
    }
    this.priBox = false

    this.toaster.success('Call resumed successfully.');
  }

  transferACall() {
    this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back

    let phoneLogin = ((this.phoneModel.extension) ? this.phoneModel.extension : '');
    let custMbNo = ((this.phoneModel.mobileNo) ? this.phoneModel.mobileNo : '');
    let parkFlag = ((this.phoneModel.callStatus) ? this.phoneModel.callStatus : '');
    // if (this.phoneModel.callStatus != 'park') {
    //   this.toaster.warning('Please park a customer before conference'); return;
    // } else {
    $('#dialBox').hide();
    $('#conCallBox').show();

    let firstCallNo = custMbNo;
    let secondCallNo = $('#conCallValue').val();
    console.log(secondCallNo)
    if (secondCallNo == '' || secondCallNo == undefined) {
      this.toaster.error('Please enter number'); return;
    } else {
      if (firstCallNo == secondCallNo) {
        this.toaster.error('Please enter different number for conference'); return;
      }

      let phoneData = { 'extension': phoneLogin }
      let type = 'attended'

      this.phoneApi.transferACall(phoneData, secondCallNo, type).subscribe(
        data => {
          console.log(parkFlag, 'parcallStatus')
          console.log(data)
          if (data != null && data != '' && data != undefined) {
            let msg = data['Desc'].toString();
            if (msg != null && msg != '' && msg != undefined) {
              if (msg.indexOf("Success") !== -1) {
                if (parkFlag == 'park') {
                  this.holdData = {
                    action: 'resume-call',
                  };
                  this.addHoldRetrieveEntry(this.holdData)
                }
                this.phoneModel.callStatus = 'callConf';
                this.phoneModel.callTransfer = 'yes';
                this.phoneModel.agentStatus = 'callConf'

                this.teleSessionData['secondNo'] = secondCallNo
                this.teleSessionData['agentStatus'] = this.phoneModel.agentStatus
                localStorage.setItem("phoneData", JSON.stringify(this.teleSessionData));
                this.showIcons(true, false, true, false, false, true, false, false, false, true, false, true, true,
                  true, false, false, false, false)
                $('#dialPadValue').val('ONCONFERENCE');
                this.toaster.success('Call added successfully.');
              } else if (msg.indexOf("ERROR") !== -1) {
                this.toaster.error('Phone error : ' + msg);
              }
            } else {
              this.toaster.error('Telephony server not responding.');
              return;
            }

          } else {
            this.toaster.error('Telephony server not responding.');
            return;
          }


        });
    }
    // }



  }

  blindATransfer() {
    let phoneLogin = ((this.phoneModel.extension) ? this.phoneModel.extension : 0);


    $('#dialBox').hide();
    $('#conCallBox').show();

    let firstCallNo = this.phoneModel.mobileNo;
    let secondCallNo = $('#conCallValue').val();
    if (secondCallNo == '' || secondCallNo == undefined) {
      this.toaster.error('Please enter number'); return;
    } else {
      if (firstCallNo == secondCallNo) {
        this.toaster.error('Please enter different number for transfer'); return;
      }

      let phoneData = { 'extension': phoneLogin }
      let callHangupData = { 'action': 'end-call' }
      let type = 'blind'


      this.phoneApi.transferACall(phoneData, secondCallNo, type).subscribe(
        data => {
          console.log(data)
          if (data != null && data != '' && data != undefined) {
            let msg = data['Desc'].toString();
            if (msg != null || msg != '' || msg != undefined) {
              if (msg.indexOf("Success") !== -1) {
                this.phoneModel.callStatus = 'callConf';
                this.phoneModel.callTransfer = 'yes';
                this.showIcons(true, false, false, true, false, false, false, false, false, false, false,
                  false, false, false, false, false, false, false)
                this.priBox = true
                this.phoneModel.contactData = ''
                this.telephonyDispositonDialog()
                this.addHangupEntry(callHangupData)
                $('#callTimer').val('00:00:00');

                this.phoneModel.callTimerEvent && this.phoneModel.callTimerEvent.unsubscribe();

                this.phoneModel.extState && this.phoneModel.extState.unsubscribe();

                this.phoneModel.mobileNo = '';
                $('#dialPadValue').val('');
                this.toaster.success('Call transfer successfully.');
              } else if (msg.indexOf("ERROR") !== -1) {
                this.toaster.error('Phone error : ' + msg);
              }
            } else {
              this.toaster.error('Telephony server not responding.');
              return;
            }

          } else {
            this.toaster.error('Telephony server not responding.');
            return;
          }
        });
    }


  }

  hangupConference() {
    let phoneLogin = ((this.phoneModel.extension) ? this.phoneModel.extension : 0);
    let secondCallNo = $('#conCallValue').val();

    let phoneData = { 'extension': phoneLogin, 'type': 'xfrhangup', 'secondCallNo': secondCallNo }

    this.phoneApi.hangupThirdParty(phoneData).subscribe(
      data => {
        console.log(data)
        if (data != null || data != '' || data != undefined) {
          let msg = data['Desc'].toString();
          console.log(msg)
          if (msg != null || msg != '' || msg != undefined) {
            if (msg.indexOf("Success") !== -1) {
              this.simpleHangup()
            } else if (msg.indexOf("ERROR") !== -1) {
              this.toaster.error('Phone error : ' + msg);
            }
          } else {
            this.toaster.error('Telephony server not responding.');
            return;
          }

        } else {
          this.toaster.error('Telephony server not responding.');
          return;
        }
      });
  }

  simpleHangup() {
    let phoneLogin = ((this.phoneModel.extension) ? this.phoneModel.extension : 0);

    let phoneData = { 'extension': phoneLogin }
    let callHangupData = { 'action': 'end-call' }

    this.phoneApi.phoneHangup(phoneData).subscribe(
      data => {
        let msg = data['Desc'].toString();
        if (msg != null || msg != '' || msg != undefined) {
          if (msg.indexOf("Success") !== -1) {
            $('#conCallValue').val(' ');
            this.showIcons(false, false, false, false, false, false, false, false, true, false, false,
              false, false, false, true, false, false, false)
            this.priBox = true
            this.phoneModel.callStatus = 'confEndAll';
            this.telephonyDispositonDialog()
            this.addHangupEntry(callHangupData)
            $('#callTimer').val('00:00:00');

            this.phoneModel.callTimerEvent && this.phoneModel.callTimerEvent.unsubscribe();

            this.phoneModel.extState && this.phoneModel.extState.unsubscribe();

            this.phoneModel.mobileNo = '';
            this.phoneModel.contactData = ''
            this.phoneModel.agentStatus = 'acw';

            this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back
            this.teleSessionData['agentStatus'] = this.phoneModel.agentStatus
            this.teleSessionData['mobileNo'] = this.phoneModel.mobileNo
            localStorage.setItem("phoneData", JSON.stringify(this.teleSessionData));

            $('#dialPadValue').val('');
            this.toaster.success('Conference disconnect successfully.');
          } else if (msg.indexOf("ERROR") !== -1) {
            this.toaster.error('Phone error : ' + msg);
          }
        } else {
          this.toaster.error('Telephony server not responding.');
          return;
        }

      },
      error => {
        this.toaster.error('Error in hangup conference functionality.');
        return;
      });
  }

  hangupThirdParty() {
    let phoneLogin = ((this.phoneModel.extension) ? this.phoneModel.extension : 0);
    let secondCallNo = $('#conCallValue').val();

    let phoneData = { 'extension': phoneLogin, 'type': 'xfrhangup', 'secondCallNo': secondCallNo }
    console.log(phoneData)
    this.phoneApi.hangupThirdParty(phoneData).subscribe(
      data => {

        if (data != null || data != '' || data != undefined) {
          let msg = data['Desc'].toString();
          if (msg != null || msg != '' || msg != undefined) {
            if (msg.indexOf("Success") !== -1) {
              $('#conCallValue').val(' ');
              let mbNo = this.phoneModel.mobileNo
              $('#dialPadValue').val(mbNo);
              this.showIcons(false, false, true, true, true, true, true, true, true, false, false, false,
                false, false, false, false, false, false)
              this.phoneModel.callStatus = 'confEndSingle';
              this.phoneModel.agentStatus = 'incall';

              this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back
              this.teleSessionData['agentStatus'] = this.phoneModel.agentStatus
              this.teleSessionData['mobileNo'] = this.phoneModel.mobileNo
              localStorage.setItem("phoneData", JSON.stringify(this.teleSessionData));

              this.toaster.success('Third party disconnect successfully.');
            } else if (msg.indexOf("ERROR") !== -1) {
              this.toaster.error('Phone error : ' + msg);
            }
          } else {
            this.toaster.error('Telephony server not responding.');
            return;
          }

        } else {
          this.toaster.error('Telephony server not responding.');
          return;
        }
      });
  }


  checkInboundCall() {
    this.newContactData = []
    let extension = this.phoneModel.extension;

    this.phoneModel.inboundCall && this.phoneModel.inboundCall.unsubscribe();
    const source = interval(7000);
    this.phoneModel.inboundCall = source.subscribe(val => { this.checkInboundCall(); })

    if (extension != null) {
      this.api.getInboundCall(extension).subscribe(
        result => {
          console.log(result)
          if (result['count'] != 0) {
            let data = result['results'][0];
            console.log(data, 'inbound data')
            if (data != '') {
              console.log('in inbound result')
              this.showIcons(true, false, true, false, true, true, true, true, true, false, false, false,
                false, false, false, false, false, false)

              let callId = ((data['id']) ? data['id'] : '');
              let queue_name = ((data['queue_name']) ? data['queue_name'] : '');
              let did_no = ((data['did_no']) ? data['did_no'] : '');
              console.log(did_no)
              let phone_number = ((data['customer_phone']) ? data['customer_phone'] : '');
              let uniqueId = ((data['unique_id']) ? data['unique_id'] : '');
              console.log(uniqueId)
              this.phoneModel.uniqueId = uniqueId
              console.log(this.phoneModel.uniqueId)

              let ivrLevel1 = ((data['ivr_option1']) ? data['ivr_option1'] : '');
              // let ext_url = ((data['ext_url']) ? data['ext_url'] : '');

              if (ivrLevel1 == 1) {
                this.language = 'English'
              } else if (ivrLevel1 == 2) {
                this.language = 'Hindi'
              } else if (ivrLevel1 == 3) {
                this.language = 'Telugu'
              } else if (ivrLevel1 == 4) {
                this.language = 'Other'
              } else {
                this.language = ''
              }

              var userid = ((this.currentUser['id']) ? this.currentUser['id'] : null);
              if (phone_number > 10) {
                this.phoneNumber = phone_number.substr(phone_number.length - 10);
              }
              this.ibcallData = {
                user_id: userid,
                called_from_phone_no: did_no,
                customer_phone_no: this.phoneNumber,
                call_type: 'inbound',
                campaign_name: queue_name,
                inbound_unique_id: this.phoneModel.uniqueId,
                extension_no: extension,
                campaign_ivr_msg: queue_name,
                language: this.language
              };

              this.extStateData = {
                extension: extension,
                state: 'busy',
              };

              this.addCallEntry(this.ibcallData)
              this.addExtState(this.extStateData)
              this.priBox = false;
              $('#dialPadValue').val(this.phoneNumber);
              let isContact = ((data['contacts']) ? data['contacts'] : '');
              let urlRoute = localStorage.getItem('urlRoute');
              localStorage.setItem('lastUrl', urlRoute);
              this.phoneModel.agentStatus = 'incall'
              this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back
              this.teleSessionData['agentStatus'] = this.phoneModel.agentStatus
              this.teleSessionData['mobileNo'] = this.phoneNumber
              this.teleSessionData['uniqueId'] = this.phoneModel.uniqueId
              localStorage.setItem("phoneData", JSON.stringify(this.teleSessionData));
              if (isContact.length > 0) {
                let name = ((data['contacts']) ? data['contacts'][0]['name'] : '');
                let id = ((data['contacts']) ? data['contacts'][0]['id'] : '');
                let contactLength = data['contacts'].length;
                console.log(contactLength)
                this.phoneModel.contactData = data['contacts']
                //let phone_number = ((data['contacts']) ? data['contacts'][0]['phone_number'] : '');
                //this.phoneModel.mobileNo = phone_number
                this.phoneModel.isCustomer = 'contact'
                this.newContactData.push({
                  'contactId': id, 'name': name, 'contactLength': contactLength, 'phone_number': this.phoneNumber, 'campaign': queue_name,
                  'did_no': did_no, 'ivrLevel1': ivrLevel1
                })
                this.openInboundPopup(this.newContactData[0], 'contact')
              } else {
                this.phoneModel.isCustomer = 'new'
                //this.phoneModel.mobileNo = phone_number
                this.newContactData.push({ 'name': "New Contact", 'contactLength': 0, 'phone_number': this.phoneNumber, 'did_no': did_no, 'campaign': queue_name, 'ivrLevel1': ivrLevel1 })
                this.openInboundPopup(this.newContactData[0], 'new')
              }
              console.log(this.newContactData)
            }
          }
        },
        error => {
          this.toaster.error('Error in inbound functionality.');
          return;
        });
    }

  }

  showIcons(onCall, login, park, dial, hangup, timer, transfer, bTransfer, dialBox, conCallBox, unpark,
    endCall, endSingle, leaveCall, dispoCall, logout, acw, pause) {

    this.phoneOnCall = onCall
    this.phoneLogin = login
    this.parkBtn = park;
    this.dialObCall = dial;
    this.hangupObCall = hangup;
    this.callTimer = timer;
    this.transferCall = transfer;
    this.blindTransfer = bTransfer;
    if (dialBox == true) {
      $('#dialBox').show();
    } else {
      $('#dialBox').hide();
    }
    if (conCallBox == true) {
      $('#conCallBox').show();
    } else {
      $('#conCallBox').hide();
    }
    this.unparkBtn = unpark;
    this.endConfCall = endCall;
    this.endSingleCall = endSingle;
    this.leaveCall = leaveCall;
    this.disposCall = dispoCall;
    this.phoneLogout = logout;
    this.phoneOnAcw = acw;
    this.phoneOnPause = pause;

  }

  showAllReports() {
    let tenant = ((this.currentUser) ? this.currentUser.tenant.sub_domain : null);

    // let tenant = 'cyret';


    console.log("tenant :----", tenant)

    if (tenant == 'cyret') {
      this.router.navigate(['/app/reports/cyret-list']);
    } else {
      this.router.navigate(['/app/reports']);

    }
  }


  openNotificationPopup() {
    const dialPadDialog = this.notificationDialog.open(NotificationPopupComponent, {
      panelClass: 'co-dialog-panel',
      data: { bulkUpload: true, notificationData: this.notifications },
      // position: { left: '230px', top: '50px' }

    });
  }

  openInboundPopup(contactData, isCustomer) {
    this.phoneModel.inboundCall && this.phoneModel.inboundCall.unsubscribe();

    this.timer()

    setTimeout(() => {
      this.checkExtensionState()
    }, 2000)

    this.phoneModel.inboundCall && this.phoneModel.inboundCall.unsubscribe();

    const dialPadDialog = this.popupPadDialog.open(IncomingPopupComponent, {
      disableClose: true,
      panelClass: 'co-dialog-panel',
      data: { bulkUpload: true, contactData: contactData, isCustomer: isCustomer },
      height: '283px',
      width: '276px'

    });



    // dialPadDialog.afterClosed().subscribe(result => {
    //   console.log('ib pop up close')
    //   console.log(this.phoneModel.extState.closed  , 'extstttttt')

    //   // if (this.phoneModel.extState.closed == true) {
    //     setTimeout(() => {
    //       this.checkExtensionState()
    //     }, 2000)
    //   // }
    // })
  }





  // startTimer() {
  //   var i = 0;
  //   this.phoneModel.callTimerEvent = setInterval(() => {
  //     const newTime = ['00:00:0' + i];
  //     const sum = newTime.reduce((acc, time) => acc.add(moment.duration(time)), moment.duration());

  //     const sum1 = Math.floor(sum.asHours());
  //     const sum2 = Math.floor(sum.minutes());
  //     const sum3 = Math.floor(sum.seconds());

  //     let ms = sum1 + ':' + sum2 + ':' + sum3;
  //     $('#callTimer').val(ms);
  //     i++;

  //   }, 1000);
  // }

  addPhoneLoginLogout(phoneData) {
    console.log(phoneData)
    this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back

    this.api.addPhoneLoginLogout(phoneData).subscribe((data) => {
      let phoneSessionId = ((data['id']) ? data['id'] : '');
      if (phoneSessionId != '') {
        let phoneSessionId = ((data['id']) ? data['id'] : '');
        this.phoneModel.phoneSessionId = phoneSessionId
        this.phoneModel.selectedPauseCode = this.selectedPause
        this.teleSessionData['selectedPauseCode'] = this.phoneModel.selectedPauseCode
        this.teleSessionData['sessionId'] = phoneSessionId
        localStorage.setItem("phoneData", JSON.stringify(this.teleSessionData));
      }
    });
  }

  addDispositionForLead() {
    let leadId = this.phoneModel.leadId;
    console.log(leadId)
    let url = localStorage.getItem('urlRoute');
    let campUrl = '/app/campaign/campaign-details/' + leadId
    var userid = ((this.currentUser['id']) ? this.currentUser['id'] : null);
    var disposVal = this.phoneModel.disposCode
    console.log(disposVal)
    var data = this.disposCodeArr.filter(i => disposVal.includes(i.key));

    this.disposData = {
      lead: leadId,
      assigned_by: userid,
      disposition: ((data[0]) ? data[0].value : '')
    };

    if (leadId) {
      this.api.addDispositionForLead(this.disposData).subscribe((success) => {
        let urlRoute = localStorage.getItem('lastUrl');
        let allData = { 'path': urlRoute };
        // this.router.navigate(['/phone-load'], { queryParams: allData });
      });
    }

  }







  timer() {
    $('#callTimer').val('00:00:00');

    var i = 0;

    this.phoneModel.callTimerEvent && this.phoneModel.callTimerEvent.unsubscribe();
    const source = interval(1000);
    this.phoneModel.callTimerEvent = source.subscribe(val => {
      const newTime = ['00:00:0' + i];
      const sum = newTime.reduce((acc, time) => acc.add(moment.duration(time)), moment.duration());

      const sum1 = Math.floor(sum.asHours());
      const sum2 = Math.floor(sum.minutes());
      const sum3 = Math.floor(sum.seconds());

      let ms = sum1 + ':' + sum2 + ':' + sum3;
      $('#callTimer').val(ms);
      i++;
    })

  }


  onItemSelected(item) {
    this.expanded = !this.expanded;
  }

  // this.router.navigate();



  onClickOpenHelp() {
    if (window.location.hostname.split('.')[1] == 'localhost') {

      window.open("https://bluewhirl.io/support/")

    } else if (window.location.hostname.split('.')[2] == 'org') {

      window.open("https://bluewhirl.io/support/")

    } else if (window.location.hostname.split('.')[2] == 'io') {

      window.open("https://bluewhirl.io/support/")
    } else if (window.location.hostname.split('.')[2] == 'com') {

      window.open("https://bluewhirl.io/support/")
    }
  }

  telephonyDispositonDialog() {
    console.log(this.phoneModel.agentStatus, 'in open of dispo')
    this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back

    let queue = ((this.phoneModel.loginCampaign) ? this.phoneModel.loginCampaign : []);

    const dialPadDialog = this.telephonyDispoDialog.open(TelephonyDispositionDialogComponent, {
      panelClass: 'co-dialog-panel',
      data: { bulkUpload: true },
      // position: { left: '230px', top: '50px' }
      disableClose: true,
      width: '354px',
      height: '400px'
    });

    dialPadDialog.afterClosed().subscribe(result => {


      $('#callTimer').val('00:00:00');

      this.phoneModel.callTimerEvent && this.phoneModel.callTimerEvent.unsubscribe();

      this.phoneModel.agentStatus = 'acw'
      this.phoneModel.uniqueId = ''



      let isLogin = ((this.phoneModel.isphoneLogin) ? this.phoneModel.isphoneLogin : '');
      let outboundId = this.phoneModel.interactionId
      let currentUrl = localStorage.getItem('urlRoute');
      let extension = ((this.phoneModel.extension) ? this.phoneModel.extension : '');

      console.log(isLogin)
      if (isLogin == 'RESUME') {
        this.checkInboundCall()
        if (!outboundId) {
          if (queue.length > 0) {

            //this.addCallEntry(this.ibcallData)

            this.phoneModel.agentStatus = 'threshold'

            this.setThreshold()
          }
        } else {
          this.phoneModel.interactionId = ''
          this.phoneModel.agentStatus = 'ready'
        }
      }
      if (isLogin == 'PAUSE') {
        this.showIcons(false, false, false, true, false, false, false, false, true, false, false, false, false,
          false, false, false, false, true)
        this.resumeBtn = false;
        this.pauseBtn = true;
        this.allPauseCode = false;
        this.phoneModel.interactionId = ''
        this.phoneModel.agentStatus = 'pause'
      }
      if (isLogin == 'NO') {
        this.showIcons(false, false, false, true, false, false, false, false, true, false, false, false, false,
          false, false, true, false, false)
        this.phoneModel.interactionId = ''
        this.phoneModel.agentStatus = 'ready'
      }
      if (isLogin == '' || isLogin == undefined) {
        this.showIcons(false, false, false, true, false, false, false, false, true, false, false, false, false,
          false, false, true, false, false)
        this.phoneModel.interactionId = ''
        this.phoneModel.agentStatus = 'ready'
      }

      this.extStateData = {
        extension: extension,
        state: this.phoneModel.agentStatus,
      };

      this.addExtState(this.extStateData)

      this.teleSessionData['interactionId'] = this.phoneModel.interactionId
      this.teleSessionData['uniqueId'] = this.phoneModel.uniqueId
      this.teleSessionData['agentStatus'] = this.phoneModel.agentStatus
      localStorage.setItem("phoneData", JSON.stringify(this.teleSessionData));
    })
  }

  setThreshold() {
    this.showIcons(false, false, false, true, false, false, false, false, true, false, false, false, false,
      false, false, false, true, false)

    let thresholdEvent = this.phoneModel.thresholdTimer

    let extension = ((this.phoneModel.extension) ? this.phoneModel.extension : '');

    console.log(this.phoneModel.thresholdTimer, 'threshold event is define')

    if (!thresholdEvent || thresholdEvent != '' || thresholdEvent != undefined) {
      this.phoneModel.thresholdTimer = setTimeout(() => {

        this.extStateData = {
          extension: extension,
          state: 'ready',
        };

        this.showIcons(false, true, false, true, false, false, false, false, true, false, false, false, false,
          false, false, false, false, false)

        this.addExtState(this.extStateData)
        this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back
        this.phoneModel.agentStatus = 'ready'
        this.phoneModel.interactionId = ''
        this.disposCallData = { 'action': 'dispose-call', 'dispose_remark': this.phoneModel.disposCode }
        this.addDisposEntry(this.disposCallData)
        this.teleSessionData['interactionId'] = this.phoneModel.interactionId
        this.teleSessionData['agentStatus'] = this.phoneModel.agentStatus
        localStorage.setItem("phoneData", JSON.stringify(this.teleSessionData));
        this.selectPauseCode(1)
      }, 15000)
    }



  }

  showPhoneIcons() {
    this.teleSessionData = JSON.parse(localStorage.getItem("phoneData")); //get them back

    let queue = ((this.phoneModel.loginCampaign) ? this.phoneModel.loginCampaign : []);

    let isLogin = ((this.phoneModel.isphoneLogin) ? this.phoneModel.isphoneLogin : '');

    if (isLogin == 'RESUME') {
      this.checkInboundCall()
      if (queue.length > 0) {
        this.selectedPause = ''
        this.selectPauseCode(1)
      }
      this.showIcons(false, true, false, true, false, false, false, false, true, false, false, false, false,
        false, false, false, false, false)
    }
    if (isLogin == 'PAUSE') {
      this.showIcons(false, false, false, true, false, false, false, false, true, false, false, false, false,
        false, false, false, false, true)
      this.resumeBtn = false;
      this.pauseBtn = true;
      this.allPauseCode = false;
    }
    if (isLogin == 'NO') {
      this.showIcons(false, false, false, true, false, false, false, false, true, false, false, false, false,
        false, false, true, false, false)
    }
    if (isLogin == '' || isLogin == undefined) {
      this.showIcons(false, false, false, true, false, false, false, false, true, false, false, false, false,
        false, false, true, false, false)
    }

  }

  // getAllPriList(event?) {
  //   if (event !== undefined) {
  //     // this.DialInputValue = event.target.value;
  //     this.sideNavController.dialInputValue = event.target.value;
  //   }
  getAllPriList(event?) {
    if (event !== undefined) {
      this.dialInputValue = event.target.value;
      this.sideNavController.dialInputValue.next(this.dialInputValue);
    }
    const ExtList = this.api.getAllPriList().subscribe((result) => {
      console.log(result)
      this.allPriList = result.results;
      this.priSelected = (this.allPriList[0]) ? (this.allPriList[0]) : ''
      this.phoneModel.priSelected = this.priSelected
    })
  }

  redirectToExternalUrl() {
    window.open("https://workflow.bluewhirl.co/")
  }

  checkToken() {
    let phoneLogin = ((this.phoneModel.extension) ? this.phoneModel.extension : 0);
    let queue = ((this.phoneModel.loginCampaign) ? this.phoneModel.loginCampaign : '');
    let agentStatus = this.phoneModel.agentStatus
    let phoneData = { 'extension': phoneLogin }
    this.phoneModel.loginToken && this.phoneModel.loginToken.unsubscribe();
    const source = interval(2000);
    this.phoneModel.loginToken = source.subscribe(val => {
      let loginToken = localStorage.getItem('token');
      // console.log(loginToken, 'loginToken')
      if (loginToken == null) {

        this.phoneModel.loginToken && this.phoneModel.loginToken.unsubscribe();

        this.toaster.warning('Token is expired please relogin');

        // if (agentStatus == 'incall') {
        if (phoneLogin != 0) {
          this.phoneApi.phoneHangup(phoneData).subscribe(
            data => {
              let msg = data['Desc'].toString();
            })
        }
        // }



        setTimeout(() => {
          if (queue.length > 0) {

            queue.forEach(element => {
              var qVal = ((element['name']) ? element['name'] : null);

              let addData = { 'extension': phoneLogin, 'action': 'remove', 'queue': qVal }

              if (phoneLogin != 0) {
                this.phoneApi.phoneLoginLogout(addData).subscribe(
                  data => {
                    let msg = data['Desc'].toString();
                  })
              }

            })
          }
        }, 1000);

        setTimeout(() => {
          this.router.navigate([loginUrl]);
          this.refreshPage();
          localStorage.removeItem('phoneData');
        }, 2000)
      }
    })
  }

  openAddCustomerContactDialog() {
    const addAccountDialog = this.addCustomerContactDialog.open(AddCustomerContactDialogComponent, {
      panelClass: 'co-dialog-panel',
      data: { email: null }
    });

    addAccountDialog.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

  openAddCustomerAccountDialog() {
    const addAccountDialog = this.addCustomerAccountDialog.open(AddCustomerAccountDialogComponent, {
      panelClass: 'co-dialog-panel'
    });

    addAccountDialog.afterClosed().subscribe(result => {
    });
  }


}
/*End telephony functions */